<template>
  <div>
    <div class="flex-between">
      <div class="title">{{ $t("myArOrders") }}</div>
      <el-button
        class="btn-black"
        @click="$router.push({ path: '/receivable-list/creat' })"
        >{{ $t("addTo") }}</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 1200px"
      header-row-class-name="word"
    >
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column
        prop="id"
        :label="$t('companyOrder')"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        :label="$t('payer')"
        width="130"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="price"
        :label="$t('orderAmount')"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type1"
        :label="$t('contractStatus')"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type3"
        :label="$t('productionStatus')"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="date"
        :label="$t('dateOfIssuanceAgain')"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="date"
        :label="$t('promisedPaymentDate')"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="zip"
        :label="$t('blockNumber')"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('operation')"
        width="180"
        align="center"
      >
        <template v-slot="scope">
          <el-button
            v-if="scope.row.type2 !== ''"
            size="small"
            class="btn-gray"
            disabled
            @click="handleEdit(scope.$index, scope.row)"
            >{{ $t("financed") }}</el-button
          >
          <el-button
            v-if="scope.row.type2 !== ''"
            size="small"
            class="btn-black"
            @click="$router.push({ path: '/financing-list/finance-info' })"
            >{{ $t("details") }}</el-button
          >
          <el-button
            v-if="scope.row.type2 === ''"
            size="small"
            class="btn-black"
            @click="canFinancing(scope.$index, scope.row)"
            >{{ $t("applyForFinancing") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        // {
        //   id: 'LT000239',
        //   name: this.$t('redStarGroup'),
        //   price: '1000',
        //   type1: this.$t('signed'),
        //   type2: this.$t('inProduction'),
        //   date: '2020-04-27',
        //   zip: 1000037,
        //   type3: this.$t('inProduction')
        // },
        //{
        //   id: 'LT000343',
        //   name: this.$t('redStarGroup'),
        //   price: '500',
        //   type1: this.$t('signed'),
        //   type2: this.$t('inProduction'),
        //   date: '2020-05-02',
        //   zip: 1000089,
        //   type3: this.$t('inProduction')
        // }, {
        //   id: 'LT000366',
        //   name: this.$t('redStarGroup'),
        //   price: '805',
        //   type1: this.$t('signed'),
        //   type2: this.$t('inProduction'),
        //   date: '2020-06-08',
        //   zip: 1000249,
        //   type3: this.$t('inProduction')
        // }, {
        //   id: 'LT000399',
        //   name: this.$t('redStarGroup'),
        //   price: '20,000',
        //   type1: this.$t('signed'),
        //   type2: '',
        //   date: '2020-08-19',
        //   zip: 10406049,
        //   type3: this.$t('notProduced')
        // }
      ],
      tableLoading: false,
    };
  },
  created() {
    this.tableLoading = false;
  },
  methods: {

    handleDelete(index, rows) {
      rows.splice(index, 1);
    },
    canFinancing() {
      this.$router.push('/financing-list/financing-create');
    }
  },
  watch: {
    tableData(val) {
      console.log(val);
      if (val.length > 0) {
        this.tableLoading = false;
      }
    }
  },
};
</script>

<style lang="scss">
.title {
  padding: 2px 10px;
  border-left: 6px solid #81d8d0;
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}
</style>
